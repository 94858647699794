<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as CUSTOMER_CONTACT_TYPES } from './store'
import ContactCommonFiltersMixin from '../../../components/contact/mixins/ContactCommonFiltersMixin'

export default {
  name: 'CustomerContactCommonFilters',
  mixins: [ContactCommonFiltersMixin],
  computed: {
    ...mapGetters({
      filters: CUSTOMER_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.GETTERS.filters
    })
  },
  methods: {
    ...mapActions({
      ...CUSTOMER_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.ACTIONS
    })
  }
}
</script>
