<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as CUSTOMER_CONTACT_TYPES } from './store'
import { TYPES as CNT_ORG_TYPES } from '../../../organization-contact/store'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../../organization/organization/customer-organization/router'
import { ROUTES as CUSTOMER_CONTACT_ROUTES } from './router'
import { ORGANIZATION_CUSTOMER_TYPE_CODE } from '@/const'

import ContactDetailMixin from '../../../components/contact/mixins/ContactDetailMixin'

export default {
  name: 'CustomerContactDetail',
  mixins: [ContactDetailMixin],
  data () {
    return {
      CUSTOMER_ORG_ROUTES,
      CUSTOMER_CONTACT_ROUTES,
      organizationTitle: 'Customer organization',
      routeNameOrganizationDetail: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL,
      title: 'Customer contact',
      titleList: 'Customer contacts',
      orgType: ORGANIZATION_CUSTOMER_TYPE_CODE
    }
  },
  computed: {
    ...mapGetters({
      ...CUSTOMER_CONTACT_TYPES.GENERIC.organization.customercontact.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...CUSTOMER_CONTACT_TYPES.GENERIC.organization.customercontact.DETAIL.ACTIONS,
      getContactList: CUSTOMER_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.ACTIONS.getList,
      createOrganizationContact: CNT_ORG_TYPES.GENERIC.organization.organizationcontact.DETAIL.ACTIONS.create
    }),
    goToOrganizationDetail () {
      this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { id: this.$route.params.id } })
    },
    goToList () {
      this.getContactList().then(() => {
        this.$router.push({ name: CUSTOMER_CONTACT_ROUTES.CUSTOMER_CONTACT_LIST })
      })
    }
  }
}
</script>
