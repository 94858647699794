<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CUSTOMER_CNT_TYPES } from './store'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../../../organization/organization/customer-organization/router'
import { ROUTES as CUSTOMER_CONTACT_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import CustomerContactTable from './CustomerContactTable'
import CustomerContactCreate from './CustomerContactCreate'
import CustomerContactDetail from './CustomerContactDetail'
import ContactListMixin from '../../../components/contact/mixins/ContactListMixin'
import CustomerContactCommonFilters from './CustomerContactCommonFilters'

export default {
  name: 'CustomerContactList',
  mixins: [ContactListMixin],
  components: {
    'component-table': CustomerContactTable,
    'common-filters': CustomerContactCommonFilters,
    'component-edit': CustomerContactCreate,
    'component-detail': CustomerContactDetail
  },
  data () {
    return {
      CUSTOMER_ORG_ROUTES,
      CUSTOMER_CONTACT_ROUTES,
      title: this.$t('Contacts'),
      rolePerm: ['organization_customercontact_list'],
      actionEnablePermission: ['organization_customercontact_enable'],
      actionDisablePermission: ['organization_customercontact_disable'],
      orgType: this.$route.params.orgType
    }
  },
  computed: {
    ...mapGetters({
      ...CUSTOMER_CNT_TYPES.GENERIC.organization.customercontact.LIST.GETTERS
    }),
    showList () {
      return (
        this.$route.name === this.CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL ||
        this.$route.name === this.CUSTOMER_CONTACT_ROUTES.CUSTOMER_CONTACT_LIST
      )
    },
    canAddInstance () {
      return hasPerm('organization_customercontact_create_with_organizations')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CUSTOMER_CNT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CUSTOMER_CNT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CUSTOMER_CNT_TYPES.GENERIC.organization.customercontact.LIST.ACTIONS
    }),
    openDetailForm (cntId) {
      this.$emit('show-contact-detail', true)
      if (this.orgId) {
        this.$router.push({
          name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_CONTACT_DETAIL, params: { orgId: this.orgId, cntId: cntId }
        })
      } else {
        this.$router.push({
          name: CUSTOMER_CONTACT_ROUTES.CUSTOMER_CONTACT_DETAIL, params: { cntId: cntId }
        })
      }
    }
  }
}
</script>
